<template>
  <b-container fluid>
    <bo-page-title>
      <template #additionalCta>
        <b-button
          v-if="isList && moduleRole('add')"
          variant="success"
          class="m-l-15 btn-rounded"
          @click="addOurWork()"
        >
          <i class="fa fa-plus-circle mr-2" />
          Add {{ objName }}
        </b-button>
        <b-button
          v-else
          variant="success"
          class="m-l-15 btn-rounded"
          :to="{name: 'BoOurWorks'}"
        >
          <i class="fas fa-list mr-2" />
          {{ objName || pageTitle || defPageTitle }} List
        </b-button>
      </template>
    </bo-page-title>

    <section id="our_work">
      <b-tabs v-if="isList">
        <b-tab title="Content">
          <HeroSection v-if="moduleRole('hero')" :modulePage="modulePage" />
          <b-card v-if="isList" no-body>
            <b-card-header>
              <b-row>
                <b-col lg="5">
                  <h5 class="card-title">{{ pageTitle }} List </h5>
                </b-col>
                <b-col lg="3">
                  <b-form-group>
                    <v-select placeholder="Select Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form @submit.prevent="doFill()" class="d-flex">
                    <b-input-group>
                    <b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
                    <b-input-group-append>
                      <b-button @click="doFill()" variant="success"><i class="fas fa-search"></i></b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <b-button
                      class="ml-1 d-inline-flex align-items-center"
                      variant="outline-success"
                      @click="doReset()"
                    >Reset</b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <div class="d-flex justify-content-end mb-3">
                <b-form-checkbox v-model="isConfigOrder" switch @change="changeConfigDisplay">
                  <strong>Config Order</strong>
                </b-form-checkbox>
              </div>
              <!-- Draggable -->
              <template v-if="isConfigOrder">
                <draggable class="row gutter-2" :list="data.data||[]" @change="changeOrderOurWork">
                  <b-col lg="3" v-for="(value, index) in data.data" :key="index" class="mt-2 cursor-move">
                    <article class="card_list">
                      <div class="wrap_thumb_list">
                        <img :src="uploader(value.aow_thumb_img)" />
                        <div class="bulle_cta">
                          <b-button v-if="moduleRole('edit')" variant="secondary" class="btn-icon" pill :to="{name: $route.name, params: { pageSlug: value.aow_id }}">
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button v-if="moduleRole('delete')" variant="danger" class="btn-icon" pill @click="doDelete(index, value)">
                            <i class="far fa-trash-alt" />
                          </b-button>
                        </div>
                      </div>
                      <div class="wrap_post_content">                        
                        <b-dropdown :variant="value.aow_is_active == 'Y' ? `success` : `danger`" :text="value.aow_is_active == 'Y' ? `Active` : `Draft`" size="sm" :disabled="loading.status">
                          <b-dropdown-item @click="changeStatus(value)">{{ value.aow_is_active == 'Y' ? 'Draft' : 'Active' }}</b-dropdown-item>
                        </b-dropdown>
                      
                        <b-badge variant="transparent" class="label-purple text-white ml-1" v-if="value.aow_is_featured == 'Y'">Featured</b-badge>
                        <p class="category">{{(value.aow_type_applications || []).join(', ')}}</p>
                        <h3>{{ value.aow_title_en || value.aow_title_id }}</h3>
                      </div>
                    </article>
                  </b-col>
                </draggable>
              </template>
              <!-- Not Draggable -->
              <template v-else>
                <b-row class="gutter-2" v-if="(data.data || []).length">
                  <b-col lg="3" v-for="(value, index) in data.data" :key="index" class="mt-2">
                    <article class="card_list">
                      <div class="wrap_thumb_list">
                        <img :src="uploader(value.aow_thumb_img)" />
                        <div class="bulle_cta">
                          <b-button v-if="moduleRole('edit')" variant="secondary" class="btn-icon" pill :to="{name: $route.name, params: { pageSlug: value.aow_id }}">
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button v-if="moduleRole('delete')" variant="danger" class="btn-icon" pill @click="doDelete(index, value)">
                            <i class="far fa-trash-alt" />
                          </b-button>
                        </div>
                      </div>
                      <div class="wrap_post_content">
                        <b-dropdown :variant="value.aow_is_active == 'Y' ? `success` : `danger`" :text="value.aow_is_active == 'Y' ? `Active` : `Draft`" size="sm" :disabled="loading.status">
                          <b-dropdown-item @click="changeStatus(value)">{{ value.aow_is_active == 'Y' ? 'Draft' : 'Active' }}</b-dropdown-item>
                        </b-dropdown>
                        
                        <b-badge variant="transparent" class="label-purple text-white ml-2" v-if="value.aow_is_featured == 'Y'">Featured</b-badge>

                        <p class="category">{{(value.aow_type_applications || []).join(', ')}}</p>
                        <h3>{{ value.aow_title_en || value.aow_title_id }}</h3>
                      </div>
                    </article>
                  </b-col>
                </b-row>
              </template>

              <b-row  v-if="!(data.data || []).length" class="justify-content-center">
                <b-col lg="3">
                  <div class="text-center">
                    <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                    <h4 align="center"><span>No {{ pageTitle }} listed yet</span></h4>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
              <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
              />
            </b-card-footer>
          </b-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>

      <template v-else>
        <Form :mrTypeApplications.sync="mrTypeApplications" @openModalCategory="openModalCategory" :mrCategoryOurWork.sync="mrCategoryOurWork" :mrTechStack.sync="mrTechStack" :row.sync="row" v-bind="passToSub" />
      </template>
    </section>


    <b-modal id="modal_category" title="Add Our Work Category" no-close-on-backdrop> 
      <validation-observer ref="VFormCategory" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitCategory)">
          <b-form-group>
            <template #label>Name (ID) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalCategory.aowc_name_id"></b-form-input>
            <VValidate name="Name (ID)" v-model="dataModalCategory.aowc_name_id" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <template #label>Name (EN) <span class="text-danger">*</span></template>
            <b-form-input v-model="dataModalCategory.aowc_name_en"></b-form-input>
            <VValidate name="Name (EN)" v-model="dataModalCategory.aowc_name_en" rules="required|min:3|max:150" />
          </b-form-group>
          <b-form-group>
            <label>Status<span class="text-danger mr5">*</span></label>
            <b-form-radio-group
              :options="Config.mr.StatusOptions"
              v-model="dataModalCategory.aowc_is_active"
            />
            <VValidate 
              name="Status" 
              v-model="dataModalCategory.aowc_is_active" 
              rules="required" 
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_category')">Cancel</b-button>
        <b-button type="button" variant="primary" @click="submitCategory">Submit</b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import SEOSection from "@/components/SEOSection"
import HeroSection from '@/components/HeroSection'

import draggable from 'vuedraggable'

const _ = global._

export default {
  extends: GlobalVue,
  components: {
    Form,
    HeroSection,
    SEOSection,
    draggable
  },
  data() {
    return {
      idKey:'aow_id',
      statusKey:'aow_is_active',
      dataModal: {},
      mrCategory: [],
      mrTechStack: [],
      mrValidationTitle: {},
      mrValidationHero: {},
      mrValidationProject: {},
      mrValidationContent: {},
      mrValidationSeo: {},
      mrTypeApplications: [],
      mrCategoryOurWork: [],
      dataModalCategory: {},

      isConfigOrder: false,
      loading: {
        status: false
      },

      showOurWork: 'N',
      showClientList: 'N'
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{}, {
        mrValidationTitle: this.mrValidationTitle,
        mrValidationHero: this.mrValidationHero,
        mrValidationProject: this.mrValidationProject,
        mrValidationContent: this.mrValidationContent,
        mrValidationSeo: this.mrValidationSeo,
        showOurWork: this.showOurWork,
        showClientList: this.showClientList
      })
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    openModalCategory(){
      this.$set(this, 'dataModalCategory', {aowc_is_active: 'Y'})
      this.$bvModal.show('modal_category')
    },
    submitCategory(){
      this.$refs.VFormCategory.validate().then(result => {
        if(!result) return

        this.swalLoading()

        Gen.apiRest("/do/BoOurWorksCategory", {
          data: {
            type: 'add',
            ...this.dataModalCategory
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this, 'dataModalCategory', {})
          this.mrCategoryOurWork.push(res.data.data.data)
          this.$bvModal.hide('modal_category')
        })
      })
    },
    changeStatus(data){
      this.$set(this.loading, 'status', true)

      const status = data.aow_is_active == "Y" ? "N" : "Y"
      Gen.apiRest("/do/"+this.modulePage, {
        data: {
          type: 'status',
          status,
          id: data.aow_id,
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message,
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 1200,
          timerProgressBar: true,
        })

        this.$set(this.loading, 'status', false)
        this.$set(data, 'aow_is_active', status)
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },
    changeConfigDisplay(){
      if(this.isConfigOrder){
        this.apiGet({ 
          query: { isConfigOrder: true } 
        })
      } else {
        this.apiGet()
      }
    },
    changeOrderOurWork: _.debounce(function() {
      Gen.apiRest("/do/"+this.modulePage, {
        data: {
          type: 'sorting',
          data: this.data.data,
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message,
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 1200,
          timerProgressBar: true,
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }, 500),

    addOurWork(){
      this.loadingOverlay = true
      Gen.apiRest("/do/"+this.modulePage, {
        data: {
          type: 'add',
        }
      }).then(res => {
        this.$router.push({name: 'BoOurWorks', params: {pageSlug: res.data.data.aow_id}})
      }).catch(err => {
        this.loadingOverlay = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>