<template>
  <b-tabs v-model="tabIndex">
    <b-tab title="Content">
      <!-- Thumbnail -->
      <b-card no-body class="position-relative">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h5 class="card-title mr-3">Thumbnail</h5>
              <b-form-checkbox v-model="row.aow_is_featured" @change="doChangeFeatured($event)" value="Y" unchecked-value="N" name="showOurWork">Featured Our Works?</b-form-checkbox>
            </div>
            
            <button @click="openModalEdit('ourworkModal')" type="button"
              class="btn btn-rounded btn-outline-success" fdprocessedid="yl9zh"><span>Quick Edit</span><i
                class="fas fa-pencil-alt ml-2"></i>
            </button>
          </div>
          <div class="button-lang bg-white">
            <b-form-group
              class="mb-0"
            >
              <b-form-radio-group
                v-model="lang"
                :options="optionsLang"
                button-variant="outline-primary"
                size="lg"
                name="radio-btn-outline"                
                buttons
              ></b-form-radio-group>
            </b-form-group>

          </div>
        </template>
        <b-row>          
          <b-col md="12" class="border-left">
            <b-card-body>
              <b-row align-v="center" class="gx-3">
                <b-col md="12" v-if="showAlertThumbnail">
                  <b-alert variant="danger" show>
                    <strong>Data has not been filled in completely, please check again</strong>
                  </b-alert>
                </b-col>

                <b-col md="4">
                  <UploaderOverlay v-model="row.aow_thumb_img" type="our_work_thumbnail" />                  
                </b-col>
                <b-col md="6">
                  <div class="thumbnail_info">
                    <p>
                      <a href="javascript:;" @click="openModalEdit('editCategoryModal')">
                        {{  row[`aowc_name_${lang}`] || 'Fill with category' }}
                        <i class="icon-pencil"></i>
                      </a>
                    </p>

                    <h2><a href="javascript:;" v-b-tooltip.hover title="Edit Text"
                        @click="openModalEdit('edittextModal')">{{ row[`aow_thumb_title_${lang}`] || 'Fill with the thumbnail title' }}
                        <i class="icon-pencil"></i></a></h2>
                    <p>
                      <a href="javascript:;" @click="openModalEdit('editexcerptModal')">
                        {{  row[`aow_project_excerpt_${lang}`] || 'Fill with excerpt' }}
                        <i class="icon-pencil"></i>
                      </a>
                    </p>
                  </div>

                  <div class="lqd-pf-details d-flex">
                    <ul class="reset-ul inline-nav lqd-pf-cat inline-flex relative z-2">
                      <li v-for="(v, k) in row.aow_type_applications" :key="`type-${k}`">
                        <a href="#">{{ v}}</a>
                      </li>
                    </ul>

                    <a href="javascript:;" @click="openModalEdit('editapplicationTypeModal')" class="edit-type-application">
                      <i class="icon-pencil"></i> {{ !(row.aow_type_applications||[]).length ? 'Fill with application type' : '' }}
                    </a>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-col>
        </b-row>


        <!-- Edit Project Name -->
        <b-modal id="edittextModal" title="Edit Text" centered size="md" hide-footer>
          <ValidationObserver ref="VFormTitle" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitTitle)">
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Project Name (ID)</span>
                      <strong class="text-danger">*</strong>
                    </template>

                    <b-form-input v-model="dataModal.aow_thumb_title_id" placeholder="e.g. Software Development"></b-form-input>
                    <VValidate name="Project Name (ID)" v-model="dataModal.aow_thumb_title_id" :rules="mrValidationTitle.aow_title" />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Project Name (EN)</span>
                      <strong class="text-danger">*</strong>
                    </template>

                    <b-form-input v-model="dataModal.aow_thumb_title_en" placeholder="e.g. Software Development"></b-form-input>
                    <VValidate name="Project Name (EN)" v-model="dataModal.aow_thumb_title_en" :rules="mrValidationTitle.aow_title" />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('edittextModal');" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>

        <b-modal id="edittextHeroModal" title="Edit Text" centered size="md" hide-footer>
          <ValidationObserver ref="VFormTitle" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitTitle)">
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Project Name (ID)</span>
                      <strong class="text-danger">*</strong>
                    </template>

                    <b-form-input v-model="dataModal.aow_title_id" placeholder="e.g. Software Development"></b-form-input>
                    <VValidate name="Project Name (ID)" v-model="dataModal.aow_title_id" :rules="mrValidationTitle.aow_title" />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Project Name (EN)</span>
                      <strong class="text-danger">*</strong>
                    </template>

                    <b-form-input v-model="dataModal.aow_title_en" placeholder="e.g. Software Development"></b-form-input>
                    <VValidate name="Project Name (EN)" v-model="dataModal.aow_title_en" :rules="mrValidationTitle.aow_title" />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('edittextHeroModal')" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        <!-- Edit Project Name-->

        <!-- Edit Excerpt -->
        <b-modal id="editexcerptModal" title="Edit Text" centered size="md" hide-footer>
          <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitProjectInformation())">
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Excerpt (ID)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-textarea rows="4" v-model="dataModal.aow_project_excerpt_id"
                      placeholder="e.g. Gopald is a digital solution to facilitate the Indramayu Regency..." />                      
                    <VValidate name="Excerpt (ID)" v-model="dataModal.aow_project_excerpt_id" :rules="mrValidationProject.aow_project_excerpt_id" />

                    </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Excerpt (EN)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-textarea rows="4"
                      placeholder="e.g. Gopald is a digital solution to facilitate the Indramayu Regency..." v-model="dataModal.aow_project_excerpt_en" />
                    <VValidate name="Excerpt (EN)" v-model="dataModal.aow_project_excerpt_en" :rules="mrValidationProject.aow_project_excerpt_en" />
                  </b-form-group>

                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('editexcerptModal')" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>

        <b-modal id="editapplicationTypeModal" title="Edit Type Applications" centered size="md" hide-footer>
          <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitProjectInformation())">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template #label>Type of Application <span class="text-danger">*</span></template>
                    <b-input-group>
                      <v-select :options="mrTypeApplications" v-model="dataModal.aow_type_applications"  multiple class="vs__multiple" style="min-width: 300px;" />
                      <b-input-group-append>
                        <b-button type="button" @click="openModalTypeApplication" variant="success" v-b-tooltip.hover.noninteractive="'Add Type of Application'"> <i class="fas fa-plus"></i> </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <VValidate name="Type of Application" v-model="dataModal.aow_type_applications" :rules="mrValidationProject.aow_type_applications"  />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('editapplicationTypeModal')" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        <!-- End Excerpt -->
        <b-modal id="editCategoryModal" title="Edit Type Applications" centered size="md" hide-footer>
          <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitProjectInformation())">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <template #label="">
                      <span>Category</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-input-group>
                      <v-select :options="mrCategoryOurWork" :reduce="v => v.value" label="label" v-model="dataModal.aow_category" style="width: 90%;"></v-select>
                      <b-input-group-append>
                        <b-button type="button" @click="$emit('openModalCategory')" variant="success" v-b-tooltip.hover.noninteractive="'Add Our Work Category'"> <i class="fas fa-plus"></i> </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <VValidate name="Category" v-model="dataModal.aow_category" :rules="mrValidationTitle.aow_category" />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('editCategoryModal')" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        
        <b-modal id="ourworkModal" title="Our Work Thumbnail" hide-footer centered size="xl">
          <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitProjectInformation)">
              <b-row>
                <b-col md="8">
                  <b-card-body>
                    <b-row>
                      <div class="col-md-8">
                        <b-form-group>
                          <template #label="">
                            <span>Category</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-input-group>
                            <v-select :options="mrCategoryOurWork" :reduce="v => v.value" label="label" v-model="dataModal.aow_category" style="width: 90%;"></v-select>
                            <b-input-group-append>
                              <b-button type="button" @click="$emit('openModalCategory')" variant="success" v-b-tooltip.hover.noninteractive="'Add Our Work Category'"> <i class="fas fa-plus"></i> </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <VValidate name="Category" v-model="dataModal.aow_category" :rules="mrValidationTitle.aow_category" />
                        </b-form-group>
                      </div>
                    </b-row>
                    <b-row>

                      <div class="col-md-6">
                        <b-form-group>
                          <template #label="">
                            <span>Thumbnail Title (ID)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input v-model="dataModal.aow_thumb_title_id" placeholder="e.g. Gopald"></b-form-input>
                          <VValidate name="Thumbnail Title (ID)" v-model="dataModal.aow_thumb_title_id" :rules="mrValidationTitle.aow_title" />
                        </b-form-group>
                      </div>

                      <div class="col-md-6">
                        <b-form-group>
                          <template #label="">
                            <span>Thumbnail Title (EN)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input v-model="dataModal.aow_thumb_title_en" placeholder="e.g. Gopald"></b-form-input>
                          <VValidate name="Thumbnail Title (EN)" v-model="dataModal.aow_thumb_title_en" :rules="mrValidationTitle.aow_title" />
                        </b-form-group>
                      </div>
                      
                      <div class="col-md-6">
                        <b-form-group>
                          <template #label="">
                            <span>Project Name (ID)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input v-model="dataModal.aow_title_id" placeholder="e.g. Gopald"></b-form-input>
                          <VValidate name="Project Name (ID)" v-model="dataModal.aow_title_id" :rules="mrValidationTitle.aow_title" />
                        </b-form-group>
                      </div>

                      <div class="col-md-6">
                        <b-form-group>
                          <template #label="">
                            <span>Project Name (EN)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input v-model="dataModal.aow_title_en" placeholder="e.g. Gopald"></b-form-input>
                          <VValidate name="Project Name (EN)" v-model="dataModal.aow_title_en" :rules="mrValidationTitle.aow_title" />
                        </b-form-group>
                      </div>

                      <b-col md="6">
                        <b-form-group class="mb-0" label="Short Description">
                          <template #label="">
                            <span>Excerpt (ID)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-textarea rows="4" v-model="dataModal.aow_project_excerpt_id"
                            placeholder="e.g. Gopald is a digital solution to facilitate the Indramayu Regency..." />                      

                          <VValidate name="Excerpt (ID)" v-model="dataModal.aow_project_excerpt_id" :rules="mrValidationProject.aow_project_excerpt_id" />
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group class="mb-0" label="Short Description">
                          <template #label="">
                            <span>Excerpt (EN)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-textarea rows="4"
                            placeholder="e.g. Gopald is a digital solution to facilitate the Indramayu Regency..." v-model="dataModal.aow_project_excerpt_en" />

                          <VValidate name="Excerpt (EN)" v-model="dataModal.aow_project_excerpt_en" :rules="mrValidationProject.aow_project_excerpt_en" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>

                <b-col md="4" class="border-left">
                  <b-card-body>
                    <h6 class="card-title">Thumbnail</h6>
                    <b-row>
                      <b-col md="12">
                        <Uploader v-model="dataModal.aow_thumb_img" type="our_work_thumbnail"/>
                        <VValidate name="Thumbnail Image" v-model="dataModal.aow_thumb_img" :rules="mrValidationHero.aow_thumb_img" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('ourworkModal')" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
      </b-card>

      
      <!-- /Thumbnail -->

      <!-- Hero Section -->

      <b-card class="mb-1">
        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title">Hero Image</h5><button type="button" @click="openModalEdit('ourworkHero')"
              class="btn btn-rounded btn-outline-success" fdprocessedid="yl9zh"><span>Quick Edit</span><i
                class="fas fa-pencil-alt ml-2"></i></button>
          </div>
        </template>
        <b-row>
          <b-col md="12" v-if="showAlertHero">
            <b-alert variant="danger" show>
              <strong>Please to make sure you already fill the data.</strong>
            </b-alert>
          </b-col>
          <b-col md="12">
            <div class="hero-image-service">
              <div class="bg-content">
                <div class="hi-main d-flex flex-column justify-content-center p-5 h-100">
                  <div class="row align-items-center">
                    <div class="col-md-8">
                      <div class="back_wrap">
                        <a href=""><img src="/assets/images/left-white.svg"
                            alt="Intuitive and Easy to Use Design"> Back to Our Work</a>
                      </div>
                      <div class="project_info">
                        <p><a href="javascript:;" v-b-tooltip.hover title="Edit Text"
                            @click="openModalEdit('editclientModal')">{{ row[`aow_client_${lang}`] || 'Fill with the company name' }} <i class="icon-pencil"></i></a>
                        </p>
                        <h1><a href="javascript:;" v-b-tooltip.hover title="Edit Text" @click="openModalEdit('edittextHeroModal')">
                            {{ row[`aow_title_${lang}`] || 'Fill with the project name' }}
                            <i class="icon-pencil"></i></a></h1>
                      </div>
                      <div class="scope_of_work">
                        <p>Scope of Work</p>
                        <ul>
                          <li v-for="(v, k) in row.aow_content_scope" :key="`dtScope-${k}`">{{ v[`name_${lang}`] }}</li>
                        </ul>
                        <a href="javascript:;" @click="openModalEdit('editscopeModal')" v-b-tooltip.hover title="Add New"><i
                            class="icon-plus"></i> Add New Scope of
                          Work</a>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <UploaderOverlay v-model="row.aow_hero_img_object" type="our_work_hero_object" :is-rounded="true" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Edit Client Name -->
              <b-modal id="editclientModal" title="Edit Text" ok-title="Submit" centered size="md" hide-footer>
                <validation-observer ref="VFormProjectInformation" v-slot="{handleSubmit}">
                  <b-form @submit.prevent="handleSubmit(submitProjectInformation)">
                    <b-row>
                      <b-col md="12" class="mb-2">
                        <b-form-group class="mb-0" label="Short Description">
                          <template #label="">
                            <span>Client Name (ID)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input placeholder="e.g Rumah Sakit Harapan" v-model="dataModal.aow_client_id"></b-form-input>

                          <VValidate name="Client Name (ID)" v-model="dataModal.aow_client_id" :rules="mrValidationProject.aow_client" />
                        </b-form-group>
                      </b-col>

                      <b-col md="12" class="mb-2">
                        <b-form-group class="mb-0" label="Short Description">
                          <template #label="">
                            <span>Client Name (EN)</span>
                            <strong class="text-danger">*</strong>
                          </template>
                          <b-form-input placeholder="e.g Rumah Sakit Harapan" v-model="dataModal.aow_client_en"></b-form-input>

                          <VValidate name="Client Name (EN)" v-model="dataModal.aow_client_en" :rules="mrValidationProject.aow_client" />
                        </b-form-group>
                      </b-col>

                      <b-col md="12">
                        <div class="text-right mt-3">
                          <b-button type="button" variant="secondary" @click="closeModal('editclientModal')" class="mr-3">Cancel</b-button>
                          <b-button type="submit" variant="primary">Submit</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
                
              </b-modal>
              <!-- Edit Client Name-->

              <!-- Edit Scope of Work -->
              <b-modal id="editscopeModal" title="Edit Text" ok-title="Submit" centered size="lg" hide-footer>
                <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(submitProjectInformation())">
                    <b-row>
                      <b-col md="12" class="mb-2">
                        <table class="table table-sm table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Scope of Work (ID)</th>
                              <th>Scope of Work (EN)</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, k) in [...dataModal.aow_content_scope]" :key="`inscope-${k}`">
                              <td>{{ k + 1 }}</td>
                              <td>
                                <b-form-input v-model="v.name_id"></b-form-input>
                                <VValidate name="Scope of Work (ID)" :vid="`scopeid${k}`" v-model="v.name_id" rules="required|min:3|max:128" />
                              </td>
                              <td>
                                <b-form-input v-model="v.name_en"></b-form-input>
                                <VValidate name="Scope of Work (EN)" :vid="`scopeen${k}`" v-model="v.name_en" rules="required|min:3|max:128" />
                              </td>

                              <td><button type="button" class="btn btn-icon btn-outline-danger btn-sm" @click="deleteSOW(k)"><i
                                    class="ti-trash"></i></button></td>
                            </tr>
                            <tr>
                              <td colspan="3" class="text-center"> <a href="javascript:;" class="link_txt" v-b-tooltip.hover
                                  title="Add New" @click="dataModal.aow_content_scope.push({})"><i class="icon-plus"></i> Add New Scope of Work</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>

                      <b-col md="12">
                        <div class="text-right mt-3">
                          <b-button type="button" variant="secondary" @click="closeModal('editscopeModal')" class="mr-3">Cancel</b-button>
                          <b-button type="submit" variant="primary">Submit</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-modal>
              <!-- Edit Client Name-->

              <b-modal id="ourworkHero" title="Our Work Hero" ok-title="Submit" hide-footer centered size="xl">
                <ValidationObserver ref="VFormProjectInformation" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(submitProjectInformation())">
                    <b-row>
                      <b-col md="8">
                        <b-card-body>
                          <b-row>
                            <div class="col-md-6">
                              <b-form-group>
                                <template #label="">
                                  <span>Client Name (ID)</span>
                                  <strong class="text-danger">*</strong>
                                </template>
                                <b-form-input placeholder="e.g Rumah Sakit Harapan" v-model="dataModal.aow_client_id"></b-form-input>
                                <VValidate name="Client Name (ID)" v-model="dataModal.aow_client_id" :rules="mrValidationProject.aow_client" />
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group>
                                <template #label="">
                                  <span>Client Name (EN)</span>
                                  <strong class="text-danger">*</strong>
                                </template>
                                <b-form-input placeholder="e.g Rumah Sakit Harapan" v-model="dataModal.aow_client_en"></b-form-input>
                                <VValidate name="Client Name (EN)" v-model="dataModal.aow_client_en" :rules="mrValidationProject.aow_client" />
                              </b-form-group>
                            </div>

                            <b-col md="6">
                              <b-form-group class="mb-0" label="Short Description">
                                <template #label="">
                                  <span>Project Name (ID)</span>
                                  <strong class="text-danger">*</strong>
                                </template>

                                <b-form-input v-model="dataModal.aow_title_id" placeholder="e.g. Software Development"></b-form-input>
                                <VValidate name="Project Name (ID)" v-model="dataModal.aow_title_id" :rules="mrValidationTitle.aow_title" />
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group class="mb-0" label="Short Description">
                                <template #label="">
                                  <span>Project Name (EN)</span>
                                  <strong class="text-danger">*</strong>
                                </template>

                                <b-form-input v-model="dataModal.aow_title_en" placeholder="e.g. Software Development"></b-form-input>
                                <VValidate name="Project Name (EN)" v-model="dataModal.aow_title_en" :rules="mrValidationTitle.aow_title" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="mt-3">
                            <b-col md="8">
                              <table class="table table-sm table-striped">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Scope of Work (ID)</th>
                                    <th>Scope of Work (EN)</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v, k) in dataModal.aow_content_scope" :key="`inallscope-${k}`">
                                    <td>{{ k + 1 }}</td>
                                    <td>
                                      <b-form-input v-model="v.name_id"></b-form-input>
                                      <VValidate name="Scope of Work (ID)" :vid="`scopeid${k}`" v-model="v.name_id" rules="required|min:3|max:128" />
                                    </td>
                                    <td>
                                      <b-form-input v-model="v.name_en"></b-form-input>
                                      <VValidate name="Scope of Work (EN)" :vid="`scopeen${k}`" v-model="v.name_en" rules="required|min:3|max:128" />
                                    </td>

                                    <td><button type="button" class="btn btn-icon btn-outline-danger btn-sm" @click="deleteSOW(k)"><i
                                          class="ti-trash"></i></button></td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" class="text-center"> <a href="javascript:;" class="link_txt" v-b-tooltip.hover
                                        title="Add New" @click="dataModal.aow_content_scope.push({})"><i class="icon-plus"></i> Add New Scope of Work</a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-col>

                      <b-col md="4" class="border-left">
                        <b-card-body>
                          <h6 class="card-title">Image</h6>
                          <b-row>
                            <b-col md="12">
                              <Uploader v-model="dataModal.aow_hero_img_object" type="our_work_hero_object"/>
                              <VValidate name="Image" v-model="dataModal.aow_hero_img_object" :rules="mrValidationHero.aow_hero_img_object" />
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-col>

                      <b-col md="12">
                        <div class="text-right mt-3">
                          <b-button type="button" variant="secondary" @click="closeModal('ourworkHero')" class="mr-3">Cancel</b-button>
                          <b-button type="submit" variant="primary">Submit</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-modal>
            </div>
          </b-col>

        </b-row>
      </b-card>
      
      <!-- /Hero Section -->      

      <!-- Content Block -->
      <b-card no-body>
        <draggable :list="row.aow_content_block" @change="updateBlockdata(row)">
          <b-card class="mb-0 mt-1" v-for="(caseData, caseKey) in row.aow_content_block" :key="`cd-${caseKey}`">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title"><strong>Section #{{ caseKey + 1 }}</strong></h5>
                <div>
                  <button @click="openModalBlock('cbFormModal', caseData, caseKey)" type="button" class="btn btn-rounded btn-outline-success" fdprocessedid="yl9zh">
                    <span>Quick Edit</span><i class="fas fa-pencil-alt ml-2"></i>
                  </button>
                  <button type="button" v-if="(row.aow_content_block||[]).length > 1" class="btn btn-rounded btn-outline-danger ml-2" @click="row.aow_content_block.splice(caseKey, 1)">
                    <span>Delete</span><i class="fas fa-trash ml-2"></i>
                  </button>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="12" v-if="showAlertBlockContent">
                <b-alert variant="danger" show>
                  <strong>Data has not been filled in completely, please check again.</strong>
                </b-alert>
              </b-col>
              <b-col md="12">
                <div class="wrap_atribut">
                  <b-row class="align-items-center">
                    <b-col md="auto">
                      <b-form-checkbox :id="`checkbox-${caseKey}`" v-model="caseData.hasImage" :name="`checkbox-${caseKey}`" @change="checkImages(caseKey)">With Image</b-form-checkbox>
                    </b-col>

                    <b-col md="2" v-if="caseData.hasImage">
                      <v-select :options="caseImageShownOpts" :reduce="caseImgOpt => caseImgOpt.value" v-model="caseData.imageType" @input="checkImages(caseKey)" />
                    </b-col>

                    <b-col md="auto">
                      <h6 class="card-title text-right mb-0">Title Placement</h6>
                    </b-col>
                    <b-col md="2">
                      <v-select :options="caseTitlePlaceOpts" :reduce="caseTitleOpt => caseTitleOpt.value" v-model="caseData.titlePlacement" />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" :order="caseData.titlePlacement == 'right' ? '1' : '0'">
                <div class="title_section case-block-content" :class="{ 'text-right': caseData.titlePlacement == 'right'}">
                  <h2>
                    <a href="javascript:;" @click="openModalBlock('edittextsectionModal', caseData, caseKey)"
                      v-b-tooltip.hover
                      title="Edit Text"
                    >
                      {{ caseData[`title_${lang}`] || 'Fill with title' }} <i class="icon-pencil"></i>
                    </a>
                  </h2>
                </div>
                
              </b-col>
              <b-col md="8">
                <div class="wrap_desc_project" v-b-tooltip.hover title="Edit Text" @click="openModalBlock('edittextalineanModal', caseData, caseKey)" v-html="caseData[`content_${lang}`]" />
              </b-col>
            </b-row>
            <b-row v-if="caseData.hasImage && caseData.imageType" class="mt-3">
              <b-col :md="caseData.imageType == 'single' ? 12 : 6" v-for="(image, kImage) in caseData.imageType == 'single' ? [ caseData.images[0] ] : caseData.images" :key="`image-${kImage}`">
                <UploaderOverlay v-model="caseData.images[kImage]" :type="caseData.imageType == 'single' ? 'our_work_content_block_single' : 'our_work_content_block_double'" :crop-not-using-height="caseData.imageType == 'single'" />
              </b-col>
            </b-row>
          </b-card>
        </draggable>

        <template #footer>
          <div v-if="sectionBlockEmpty">
            <b-alert variant="danger" show>
              <strong>Please to make sure add content block.</strong>
            </b-alert>
          </div>
          <div class="text-center">
            <b-button variant="success" class="btn-rounded" @click="addBlockContent()">
              <i class="icon-plus"></i>
              <span class="ml-2">Add Content Block</span>
            </b-button>
          </div>
        </template>

        <!-- Edit Title -->
        <b-modal id="edittextsectionModal" title="Edit Text" ok-title="Submit" centered size="md" hide-footer>
          <ValidationObserver ref="VFormBlockContent" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitBlockContent)">
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Title (ID)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-input placeholder="e.g. Desain yang Intuitif dan Mudah Digunakan" v-model="dataModal.title_id" />
                    <VValidate name="Title (ID)" v-model="dataModal.title_id" :rules="mrValidationContent.title_id" />
                  </b-form-group>
                </b-col>
    
                <b-col md="12">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Title (EN)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-input placeholder="e.g. Desain yang Intuitif dan Mudah Digunakan" v-model="dataModal.title_en" />
                    <VValidate name="Title (EN)" v-model="dataModal.title_en" :rules="mrValidationContent.title_en" />
                  </b-form-group>
                </b-col>
    
                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('edittextsectionModal');" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        <!-- Edit Title-->

        <!-- Edit Alinea -->
        <b-modal id="edittextalineanModal" title="Edit Text" ok-title="Submit" centered size="lg" hide-footer>
          <ValidationObserver ref="VFormBlockContent" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitBlockContent)">
              <b-row>
                <b-col md="12" class="mb-2">
                  <b-form-group label-for="blockTitle">
                    <label>Content (ID) <span class="text-danger">*</span></label>
                    <QuillEditor 
                      id="articleContentID"
                      :value.sync="dataModal.content_id"
                      :customToolbar="itemsToolbar"
                    />
                    <VValidate 
                      name="Content (ID)" 
                      v-model="dataModal.content_id" 
                      :rules="{ required: true }" 
                    />
                  </b-form-group>
    
                </b-col>
                <b-col md="12" class="mb-2">
                  <b-form-group label-for="blockTitle">
                    <label>Content (EN) <span class="text-danger">*</span></label>
                    <QuillEditor 
                      id="articleContentEN"
                      :value.sync="dataModal.content_en"
                      :customToolbar="itemsToolbar"
                    />
                    <VValidate 
                      name="Content (EN)" 
                      v-model="dataModal.content_en" 
                      :rules="{ required: true }" 
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('edittextalineanModal');" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        <!-- Edit Paraghraph-->

        <!-- Quick Edit -->
        <b-modal id="cbFormModal" title="Content Block" ok-title="Submit" centered size="xl" hide-footer>
          <ValidationObserver ref="VFormBlockContent" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitBlockContent)">
              <b-row>
                <b-col md="12">
                  <div class="wrap_atribut">
                    <b-row class="align-items-center">
                      <b-col md="auto">
                        <b-form-checkbox :id="`checkbox-modal`" v-model="dataModal.hasImage" :name="`checkbox-modal`" @change="checkImages(caseKey)">With Image</b-form-checkbox>
                      </b-col>

                      <b-col md="2" v-if="dataModal.hasImage">
                        <v-select :options="caseImageShownOpts" :reduce="caseImgOpt => caseImgOpt.value" v-model="dataModal.imageType" />
                      </b-col>

                      <b-col md="auto">
                        <h6 class="card-title text-right mb-0">Title Placement</h6>
                      </b-col>
                      <b-col md="2">
                        <v-select :options="caseTitlePlaceOpts" :reduce="caseTitleOpt => caseTitleOpt.value" v-model="dataModal.titlePlacement" />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Title (ID)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-input placeholder="e.g. Desain yang Intuitif dan Mudah Digunakan" v-model="dataModal.title_id" />
                    <VValidate name="Title (ID)" v-model="dataModal.title_id" :rules="mrValidationContent.title_id" />
                  </b-form-group>
                </b-col>

                <b-col md="6" class="mb-2">
                  <b-form-group class="mb-0" label="Short Description">
                    <template #label="">
                      <span>Title (EN)</span>
                      <strong class="text-danger">*</strong>
                    </template>
                    <b-form-input placeholder="e.g. Desain yang Intuitif dan Mudah Digunakan" v-model="dataModal.title_en" />
                    <VValidate name="Title (EN)" v-model="dataModal.title_en" :rules="mrValidationContent.title_en" />
                  </b-form-group>
                </b-col>

                <b-col md="6" class="mb-2">
                  <b-form-group label-for="blockTitle">
                    <label>Content (ID) <span class="text-danger">*</span></label>
                    <QuillEditor 
                      id="articleContentMdlID"
                      :value.sync="dataModal.content_id"
                      :customToolbar="itemsToolbar"
                    />
                    <VValidate 
                      name="Content (ID)" 
                      v-model="dataModal.content_id" 
                      :rules="{ required: true }" 
                    />
                  </b-form-group>

                </b-col>
                <b-col md="6" class="mb-2">
                  <b-form-group label-for="blockTitle">
                    <label>Content (EN) <span class="text-danger">*</span></label>
                    <QuillEditor 
                      id="articleContentMdlEN"
                      :value.sync="dataModal.content_en"
                      :customToolbar="itemsToolbar"
                    />
                    <VValidate 
                      name="Content (EN)" 
                      v-model="dataModal.content_en" 
                      :rules="{ required: true }" 
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="dataModal.hasImage && dataModal.imageType" class="mt-3">
                <b-col :md="dataModal.imageType == 'single' ? 12 : 6" v-for="(image, kImage) in dataModal.imageType == 'single' ? [ dataModal.images[0] ] : dataModal.images" :key="`image-modal-${kImage}`">
                  <UploaderOverlay v-model="dataModal.images[kImage]" :type="dataModal.imageType == 'single' ? 'our_work_content_block_single' : 'our_work_content_block_double'" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <div class="text-right mt-3">
                    <b-button type="button" variant="secondary" @click="closeModal('cbFormModal');" class="mr-3">Cancel</b-button>
                    <b-button type="submit" variant="primary">Submit</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-modal>
        <!--  -->
      </b-card>
      <!-- / Content Block -->

      <!-- Commented -->
      <template v-if="false">
        <!-- Hero Section -->
        <b-card no-body>
          <template #header>
            <h5 class="card-title">Hero Image & Thumbnail</h5>
          </template>
          <validation-observer ref="VFormHero" v-slot="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitHero)">
              <b-row class="g-0">
                <b-col md="4">
                  <b-card-body>
                    <h6 class="card-title">Thumbnail</h6>
                    <b-form-group>
                      <template #label>Thumbnail Image <span class="text-danger">*</span></template>
                      <Uploader v-model="row.aow_thumb_img" type="our_work_thumbnail"/>
                      <VValidate name="Thumbnail Image" v-model="row.aow_thumb_img" :rules="mrValidationHero.aow_thumb_img" />
                    </b-form-group>
                  </b-card-body>
                </b-col>
                <b-col md="8" class="border-left">
                  <b-card-body>
                    <h6 class="card-title">Hero Image</h6>
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <template #label>Hero Image Background<span class="text-danger">*</span></template>
                          <Uploader v-model="row.aow_hero_img_bg" type="our_work_hero_bg"/>
                          <VValidate name="Hero Image Background" v-model="row.aow_hero_img_bg" :rules="mrValidationHero.aow_hero_img_bg" />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <template #label>Hero Image Object<span class="text-danger">*</span></template>
                          <Uploader v-model="row.aow_hero_img_object" type="our_work_hero_object"/>
                          <VValidate name="Hero Image Object" v-model="row.aow_hero_img_object" :rules="mrValidationHero.aow_hero_img_object" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="6" class="border-top">
                  <b-card-body>
                    <b-form-group class="mb-0">
                      <template #label>Short Description (ID) <span class="text-danger">*</span></template>
                      <b-form-textarea rows="4" placeholder="e.g. Gopald adalah solusi digital untuk memudahkan Pemerintah Kabupaten Indramayu..." v-model="row.aow_short_desc_id" />
                      <VValidate name="Short Description (ID)" v-model="row.aow_short_desc_id" :rules="mrValidationHero.aow_short_desc_id" />
                    </b-form-group>
                  </b-card-body>
                </b-col>
                <b-col md="6" class="border-top">
                  <b-card-body>
                    <b-form-group class="mb-0">
                      <template #label>Short Description (EN) <span class="text-danger">*</span></template>
                      <b-form-textarea rows="4" placeholder="e.g. Gopald is a digital solution to facilitate the Indramayu Regency..." v-model="row.aow_short_desc_en" />
                      <VValidate name="Short Description (EN)" v-model="row.aow_short_desc_en" :rules="mrValidationHero.aow_short_desc_en" />
                    </b-form-group>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <template #footer>
            <div class="text-right">
              <b-button @click="submitHero" variant="success" class="btn-rounded">Save</b-button>
            </div>
          </template>
        </b-card>
        <!-- / Hero Section-->

        <!-- Project Information -->
        <b-card>
          <template #header>
            <h5 class="card-title">Project Information</h5>
          </template>
          <validation-observer ref="VFormProjectInformation" v-slot="{handleSubmit}">
            <b-form @submit="handleSubmit(submitProjectInformation)">
              <b-row>
                <b-col md="3">
                  <b-form-group>
                    <template #label>Client <span class="text-danger">*</span></template>
                    <b-form-input v-model="row.aow_client"></b-form-input>
                    <VValidate name="Client" v-model="row.aow_client" :rules="mrValidationProject.aow_client" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <template #label>Brand <span class="text-danger">*</span></template>
                    <b-form-input v-model="row.aow_brand"></b-form-input>
                    <VValidate name="Brand" v-model="row.aow_brand" :rules="mrValidationProject.aow_brand" />
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group>
                    <template #label>Type of Application <span class="text-danger">*</span></template>
                    <b-input-group>
                      <v-select :options="mrTypeApplications" v-model="row.aow_type_applications"  multiple class="vs__multiple" style="min-width: 300px;" />
                      <b-input-group-append>
                        <b-button type="button" @click="openModalTypeApplication" variant="success" v-b-tooltip.hover.noninteractive="'Add Type of Application'"> <i class="fas fa-plus"></i> </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <VValidate name="Type of Application" v-model="row.aow_type_applications" :rules="mrValidationProject.aow_type_applications"  />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label="">Project Excerpt (ID) <span class="text-danger">*</span></template>
                    <b-form-textarea v-model="row.aow_project_excerpt_id" rows="4" placeholder="e.g. The non-centralized Domestic Wastewater Management service causes many..." />
                    <VValidate name="Project Excerpt (ID)" v-model="row.aow_project_excerpt_id" :rules="mrValidationProject.aow_project_excerpt_id" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label>Project Excerpt (EN)<span class="text-danger">*</span></template>
                    <b-form-textarea v-model="row.aow_project_excerpt_en" rows="4" placeholder="e.g. The non-centralized Domestic Wastewater Management service causes many..." />
                    <VValidate name="Project Excerpt (EN)" v-model="row.aow_project_excerpt_en" :rules="mrValidationProject.aow_project_excerpt_en" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <template #label>Project Difficulty <span class="text-danger">*</span></template>
                    <b-radio-group :options="globalComp.Config.mr.statusProjectDifficulty" v-model="row.aow_project_difficulty" />
                    <VValidate name="Project Difficulty" v-model="row.aow_project_difficulty" :rules="mrValidationProject.aow_project_difficulty" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label>Technology Used <span class="text-danger">*</span>
                    </template>
                    <b-input-group>
                      <v-select multiple v-model="row.aow_project_tech" label="name" :options="mrTechStack" class="vs__multiple" style="min-width: 300px;">
                        <template v-slot:option="option">
                          <img :src="uploader(option.image)" />
                          {{ option.name }}
                        </template>
                      </v-select>
                      <b-input-group-append>
                        <b-button type="button" @click="openModalTechStack" variant="success" v-b-tooltip.hover.noninteractive="'Add Tech'"> <i class="fas fa-plus"></i> </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <template #footer>
            <div class="text-right">
              <b-button @click="submitProjectInformation" variant="success" class="btn-rounded">Save</b-button>
            </div>
          </template>
          </b-card>
          <!-- / Project Information -->
        <!-- Scoped -->
        <b-card>
            <template #header>
              <h5 class="card-title">Scope We Handled</h5>
            </template>
            <b-row cols="2" cols-md="4" cols-lg="6" align-h="center" class="g-2">
              <b-col v-for="(value, index) in row.aow_content_scope" :key="index">
                <div class="card_list h-100">
                  <div class="wrap_post_content">
                    <img :src="uploader(value.image)" alt="">
                    <h3>{{value.name_en}}</h3>
                    <div class="mt-2">
                    <b-button variant="secondary" class="btn-icon mr-1" pill @click="openModalScope(value)">
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button variant="danger" class="btn-icon" pill @click="doDelete('aow_content_scope', value.id)">
                      <i class="far fa-trash-alt" />
                    </b-button>
                  </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <template #footer>
              <div class="text-center">
                <b-button variant="success" class="btn-rounded" @click="openModalScope({is_new: 'Y'})">
                  <i class="icon-plus"></i>
                  <span class="ml-2">Add Scope</span>
                </b-button>
              </div>
            </template>
        </b-card>
        <!-- Scoped -->

        <!-- Carousel -->
        <b-card no-body>
          <template #header>
            <h5 class="card-title">Project Overview Carousel</h5>
          </template>
          <b-card-body>
            <b-row class="g-2">
              <b-col md="4" v-for="(value, index) in row.aow_content_carousel" :key="index">
                <div class="card-img">
                  <b-img fluid class="card-img__media" :src="uploader(value.image)" :width="717" :height="773" />
                  <div class="bullet-cta">
                    <b-button variant="secondary" size="sm" pill @click="openModalCarousel(value)" class="btn-icon" v-b-tooltip.hover="'Update Content'">
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button variant="danger" @click="doDelete('aow_content_carousel', value.id)" size="sm" pill class="btn-icon" v-b-tooltip.hover="'Delete Content'">
                      <i class="fas fa-trash-alt" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <template #footer>
            <div class="text-center">
              <b-button variant="success" @click="openModalCarousel({is_new: 'Y'})" class="btn-rounded">
                <i class="icon-plus mr-2"></i>
                <span>Add Carousel Item</span>
              </b-button>
            </div>
          </template>
        </b-card>
        <!-- / Carousel -->
      </template>

      <!-- Show Client -->
      <b-overlay :show="loadingShow" v-if="globalComp.moduleRole('client_list')">
        <b-card>
          <b-card-header class="p-0 pt-2 pb-2">
            <div class="d-flex justify-content-between">
              <h5 class="card-title">Client List</h5>
              <div class="text-right">
                <b-button :to="{name: 'BoOurWorks'}" class="btn-rounded" variant="primary">
                  Client List
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </div>

          </b-card-header>
          <b-card-body class="p-0 pt-2 pb-2">
            <div class="d-flex justify-content-between">
              <b-form-checkbox v-model="showClientList" @change="doChangeShowContent('show-ow-client-list', $event)" value="Y" unchecked-value="N" name="showClientList">Show Section?</b-form-checkbox>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
      <!-- /Show Client -->

      <!-- Show Related Work -->
      <b-overlay :show="loadingShow" v-if="globalComp.moduleRole('our_work')">
        <b-card>
          <b-card-header class="p-0 pt-2 pb-2">
            <div class="d-flex justify-content-between">
              <h5 class="card-title">Related Work</h5>
              <div class="text-right">
                <b-button :to="{name: 'BoOurWorks'}" class="btn-rounded" variant="primary">
                  Related Work
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </div>

          </b-card-header>
          <b-card-body class="p-0 pt-2 pb-2">
            <div class="d-flex justify-content-between">
              <b-form-checkbox v-model="showOurWork" @change="doChangeShowContent('show-ow-list', $event)" value="Y" unchecked-value="N" name="showOurWork">Show Section?</b-form-checkbox>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
      <!-- /Show Related Work -->


      <b-card no-body v-if="row.aow_is_active != 'Y'">
        <template #footer>
          <div class="text-right">
            <b-button 
            type="submit" 
            pill variant="primary" 
            @click="publishOurWork()" 
            :disabled="!enableButtonSubmit"
          >
              <i class="icon-check mr-2"></i> Publish / Submit
            </b-button>
          </div>
        </template>
      </b-card>

      <b-modal id="modal_type_application" title="Add Type of Application" no-close-on-backdrop> 
        <validation-observer ref="VFormType" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitType)">
            <b-form-group>
              <template #label>Name <span class="text-danger">*</span></template>
              <b-form-input v-model="dataModalType.name" placeholder="e.g. Mobile Application"></b-form-input>
              <VValidate name="Name" v-model="dataModalType.name" rules="required|min:3|max:32" />
            </b-form-group>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <b-button type="button" variant="secondary" @click="closeModal('modal_type_application')">Cancel</b-button>
          <b-button type="button" variant="primary" @click="submitType">Submit</b-button>
        </template>
      </b-modal>



    </b-tab>
    <b-tab title="SEO">
      <b-card no-body>
        <b-card-header>
          <b-card-title title-tag="h5">SEO Settings</b-card-title>
        </b-card-header>
        <validation-observer ref="VFormSEO" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(submitSeo)">
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <template #label>Meta Title (ID) <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                      <i class="fas fa-question"></i></b-badge></template>
                      <b-form-input placeholder="Meta Title" v-model="row.aow_seo_title_id"></b-form-input>
                      <VValidate name="Meta Title (ID)" v-model="row.aow_seo_title_id" :rules="{...mrValidationSeo.aow_seo_title_id}" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label>Meta Title (EN) <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                      <i class="fas fa-question"></i></b-badge></template>
                      <b-form-input placeholder="Meta Title" v-model="row.aow_seo_title_en"></b-form-input>
                      <VValidate name="Meta Title (EN)" v-model="row.aow_seo_title_en" :rules="{...mrValidationSeo.aow_seo_title_en}" />
                  </b-form-group>
                </b-col>
                
                <b-col md="6">
                  <b-form-group>
                    <template #label>
                      Meta Description (ID) <b-badge variant="info" pill class="help-badge"
                        v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                        <i class="fas fa-question"></i></b-badge>
                    </template>
                    <b-form-textarea rows="4" placeholder="Meta Description" v-model="row.aow_seo_desc_id"/>
                    <VValidate 
                      name="Meta Description (ID)" 
                      v-model="row.aow_seo_desc_id"
                      :rules="{...mrValidationSeo.aow_seo_desc_id}" 
                    /> 
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label>
                      Meta Description (EN) <b-badge variant="info" pill class="help-badge"
                        v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                        <i class="fas fa-question"></i></b-badge>
                    </template>
                    <b-form-textarea rows="4" placeholder="Meta Description" v-model="row.aow_seo_desc_en"/>
                    <VValidate 
                      name="Meta Description (EN)" 
                      v-model="row.aow_seo_desc_en"
                      :rules="{...mrValidationSeo.aow_seo_desc_en}" 
                    /> 
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <template #label>
                      Meta Keywords (ID)
                      <b-badge variant="info" pill class="help-badge"
                        v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Tips: Gunakan keyword yang relevan dan user friendly, tidak lebih dari 10 keyword.'">
                        <i class="fas fa-question"></i>
                      </b-badge>
                    </template>
                    <b-form-tags id="homeMetaTags" v-model="row.aow_seo_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="globalComp.validatorTags"
                    tag-variant="primary" tag-class="text-white"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <template #label>
                      Meta Keywords (EN)
                      <b-badge variant="info" pill class="help-badge"
                        v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Tips: Gunakan keyword yang relevan dan user friendly, tidak lebih dari 10 keyword.'">
                        <i class="fas fa-question"></i>
                      </b-badge>
                    </template>
                    <b-form-tags id="homeMetaTags" v-model="row.aow_seo_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="globalComp.validatorTags"
                    tag-variant="primary" tag-class="text-white"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #footer>
          <div class="text-right">
            <b-button @click="submitSeo" variant="success" class="btn-rounded">Save</b-button>
          </div>
        </template>
      </b-card>
    </b-tab>
  </b-tabs>
</template>
<script>
import QuillEditor from '@/components/QuillEditor'

import Gen from '@/libs/Gen.js'
import draggable from 'vuedraggable'
const _ = global._

export default {
  components: {
    QuillEditor,
    draggable
  },
  props: {
    row: Object,
    showOurWork: String,
    showClientList: String,
    mrValidationTitle: Object,
    mrValidationHero: Object,
    mrValidationProject: Object,
    mrValidationContent: Object,
    mrValidationSeo: Object,
    mrTypeApplications: Array,
    mrTechStack: Array,
    mrCategoryOurWork: Array,
  },
  data(){
    return {
      tabIndex: 0,
      contentUpdating: {
        hero: false,
        title: false
      },
      dataModalTitle: {},
      dataModalType: {},
      dataModalTechStack: {},
      dataModalContent: {},
      dataModalScope: {},
      dataModalCarousel: {},
      dataModal: {},

      loadingShow: false,
      cbLang: 'id',
      itemsToolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
      ],

      caseImageShownOpts: [
        { value: 'single', label: 'Single' },
        { value: 'double', label: 'Double' },
      ],
      caseTitlePlaceOpts: [
        { value: 'left', label: 'Left' },
        { value: 'right', label: 'Right' },
      ],

      lang: "en",
      optionsLang: ["id", "en"]
    }
  },
  methods: {
    valueContent(field, index){
      return this.row.aow_content_block[index][field + '_' + this.cbLang]
    },
    uploader(file){
      return this.globalComp.uploader(file)
    },
    setUpdatingTitle(bool){
      if(bool){
        const payload = {
          aow_title: this.row.aow_title,
          aow_category: this.row.aow_category,
        }
        this.dataModalTitle = _.clone(payload)
      }else{
        this.dataModalTitle = {}
      }
      this.contentUpdating.title = bool
    },
    submitTitle(){
      this.$refs.VFormTitle.validate().then(result => {
        if(!result) return 

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-title',
            aow_id: this.row.aow_id,
            ...this.dataModal
          }
        },
        "POST").then(res => {
          this.$swal({
            icon: 'success',
              title: res.data.message
          })

          this.$set(this.row, 'aow_thumb_title_en', res.data.data.aow_thumb_title_en)
          this.$set(this.row, 'aow_thumb_title_id', res.data.data.aow_thumb_title_id)
          this.$set(this.row, 'aow_title_en', res.data.data.aow_title_en)
          this.$set(this.row, 'aow_title_id', res.data.data.aow_title_id)
          this.$set(this.row, 'aow_type_applications', res.data.data.aow_type_applications)

          this.closeModal('edittextModal')
          this.closeModal('edittextHeroModal')
          this.closeModal('editapplicationTypeModal')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    submitHero(){
      this.$refs.VFormHero.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-hero',
            aow_id: this.row.aow_id,
            aow_thumb_img: this.row.aow_thumb_img,
            aow_hero_img_bg: this.row.aow_hero_img_bg,
            aow_hero_img_object: this.row.aow_hero_img_object,
            aow_short_desc_id: this.row.aow_short_desc_id,
            aow_short_desc_en: this.row.aow_short_desc_en,
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message,
          })

          this.$set(this.row, 'aow_thumb_img', res.data.data.aow_thumb_img)
          this.$set(this.row, 'aow_hero_img_bg', res.data.data.aow_hero_img_bg)
          this.$set(this.row, 'aow_hero_img_object', res.data.data.aow_hero_img_object)
          this.$set(this.row, 'aow_short_desc_id', res.data.data.aow_short_desc_id)
          this.$set(this.row, 'aow_short_desc_en', res.data.data.aow_short_desc_en)
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!" 
          })
        })
      })
    },
    openModalTypeApplication(){
      this.$set(this, 'dataModalType', {})

      this.$bvModal.show('modal_type_application')
    },
    submitType(){
      this.$refs.VFormType.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'add-type',
            ...this.dataModalType
          }
        }).then(res => {
          this.mrTypeApplications.push(res.data.data)
          this.$swal({
            icon: 'success',
            title: res.data.message,
          })
          this.$bvModal.hide('modal_type_application')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!" 
          })
        })
      })
    },
    openModalTechStack(){
      this.$set(this, 'dataModalTechStack', {})
      this.$bvModal.show('modal_tech_stack')
    },
    submitTechStack(){
      this.$refs.VFormTech.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'add-tech-stack',
            ...this.dataModalTechStack
          }
        }).then(res => {
          const newTech = {name: res.data.data.mts_name, image: res.data.data.mts_image}
          this.mrTechStack.push(newTech)

          this.$swal({
            icon: 'success',
            title: res.data.message,
          })
          this.$bvModal.hide('modal_tech_stack')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!" 
          })
        })
      })
    },
    submitProjectInformation(){
      this.$refs.VFormProjectInformation.validate().then(result => {
        if(!result) return 

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-project-information',
            aow_id: this.dataModal.aow_id,
            ...this.dataModal
          }
        }).then(res => {
          this.$set(this.row, 'aow_category', res.data.data.aow_category)
          this.$set(this.row, 'aowc_name_en', res.data.data.aowc_name_en)
          this.$set(this.row, 'aowc_name_id', res.data.data.aowc_name_id)
          this.$set(this.row, 'aow_title_en', res.data.data.aow_title_en)
          this.$set(this.row, 'aow_title_id', res.data.data.aow_title_id)
          this.$set(this.row, 'aow_thumb_title_en', res.data.data.aow_thumb_title_en)
          this.$set(this.row, 'aow_thumb_title_id', res.data.data.aow_thumb_title_id)
          this.$set(this.row, 'aow_client_id', res.data.data.aow_client_id)
          this.$set(this.row, 'aow_client_en', res.data.data.aow_client_en)
          this.$set(this.row, 'aow_project_excerpt_id', res.data.data.aow_project_excerpt_id)
          this.$set(this.row, 'aow_project_excerpt_en', res.data.data.aow_project_excerpt_en)
          this.$set(this.row, 'aow_thumb_img', res.data.data.aow_thumb_img)
          this.$set(this.row, 'aow_hero_img_object', res.data.data.aow_hero_img_object)
          this.$set(this.row, 'aow_content_scope', res.data.data.aow_content_scope)
          this.$set(this.row, 'aow_type_applications', res.data.data.aow_type_applications)

          this.$swal({
            icon: 'success',
            title: res.data.message
          })

          this.closeModal('editexcerptModal')
          this.closeModal('ourworkModal')
          this.closeModal('editclientModal')
          this.closeModal('editscopeModal')
          this.closeModal('ourworkHero')
          this.closeModal('editapplicationTypeModal')
          this.closeModal('editCategoryModal')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!" 
          })
        })
      })
    },
    closeModal(id){
      this.$bvModal.hide(id)
    },
    openModalContent(value){
      this.$set(this, 'dataModalContent', _.clone(value))
      this.$bvModal.show('modal_content_block')
    },
    submitContent(){
      this.$refs.VFormContent.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-content',
            aow_id: this.row.aow_id,
            ...this.dataModalContent
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.row, 'aow_content_block', res.data.data)
          this.$bvModal.hide('modal_content_block')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    openModalScope(value){
      this.$set(this, 'dataModalScope', _.clone(value))
      this.$bvModal.show('modal_scope')
    },
    submitScope(){
      this.$refs.VFormScope.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-scope',
            aow_id: this.row.aow_id,
            ...this.dataModalScope
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.row, 'aow_content_scope', res.data.data)
          this.$bvModal.hide('modal_scope')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    openModalCarousel(value){
      this.$set(this, 'dataModalCarousel', _.clone(value))
      this.$bvModal.show('modal_carousel')
    },
    submitCarousel(){
      this.$refs.VFormCarousel.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-carousel',
            aow_id: this.row.aow_id,
            ...this.dataModalCarousel
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$set(this.row, 'aow_content_carousel', res.data.data)
          this.$bvModal.hide('modal_carousel')
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    doDelete(column, id){
      this.globalComp.swalDelete()
      .then(result => {
        if(result.value){
          this.globalComp.swalLoading()

          Gen.apiRest("/do/BoOurWorks", {
            data: {
              type: 'delete-list-content',
              column: column,
              id: id,
              aow_id: this.row.aow_id
            }
          }).then(res => {
            this.$set(this.row, column, res.data.data)
            this.$swal({
              icon: 'success',
              title: res.data.message
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    },
    submitSeo(){
      this.$refs.VFormSEO.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoOurWorks", {
          data: {
            type: 'submit-seo',
            aow_id: this.row.aow_id,
            aow_seo_title_id: this.row.aow_seo_title_id,
            aow_seo_title_en: this.row.aow_seo_title_en,
            aow_seo_desc_id: this.row.aow_seo_desc_id,
            aow_seo_desc_en: this.row.aow_seo_desc_en,
            aow_seo_keyword_id: this.row.aow_seo_keyword_id,
            aow_seo_keyword_en: this.row.aow_seo_keyword_en,
          }
        }).then(res => {
          this.$set(this.row, 'aow_seo_title_id', res.data.data.aow_seo_title_id)
          this.$set(this.row, 'aow_seo_title_en', res.data.data.aow_seo_title_en)
          this.$set(this.row, 'aow_seo_desc_id', res.data.data.aow_seo_desc_id)
          this.$set(this.row, 'aow_seo_desc_en', res.data.data.aow_seo_desc_en)
          this.$set(this.row, 'aow_seo_keyword_id', res.data.data.aow_seo_keyword_id)
          this.$set(this.row, 'aow_seo_keyword_en', res.data.data.aow_seo_keyword_en)
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },

    doChangeShowContent(slug, value){
      Gen.apiRest("/do/BoOurWorks", {
        data: {
          type: 'submit-show-content',
          value: value,
          slug: slug
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },

    doChangeFeatured(value){
      Gen.apiRest("/do/BoOurWorks", {
        data: {
          type: 'update-featured',
          aow_is_featured: value,
          aow_id: this.row.aow_id,
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },

    openModalEdit(modal){
      this.$set(this, 'dataModal', _.clone(this.row))
      this.$bvModal.show(modal)
    },

    openModalBlock(modal, data, key){
      this.$set(this, 'dataModal', { ..._.clone(data), key })
      this.$bvModal.show(modal)
    },

    deleteSOW(key){
      this.dataModal.aow_content_scope.splice(key, 1)
    },

    updateBlockdata: _.debounce(row => {
      Gen.apiRest("/do/BoOurWorks", {
        data: {
          type: 'submit-content-block-batch',
          aow_id: row.aow_id,
          aow_content_block: row.aow_content_block,
        }
      }).then(() => {
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    }, 1000),

    checkImages(key){
      this.$set(this.row.aow_content_block[key], 'images', [ "", "" ])
    },

    submitBlockContent() {
      this.$refs.VFormBlockContent.validate().then(valid => {
        if(!valid) return false

        this.$set(this.row.aow_content_block, this.dataModal.key, {
          ...this.row.aow_content_block[this.dataModal.key],
          title_id: this.dataModal.title_id,
          title_en: this.dataModal.title_en,
          content_id: this.dataModal.content_id,
          content_en: this.dataModal.content_en,
        })

        this.closeModal('edittextsectionModal')
        this.closeModal('edittextalineanModal')
        this.closeModal('cbFormModal')
      })
    },

    addBlockContent(){
      this.row.aow_content_block.push({
        content_id: "Description",
        content_en: "Description",
      })
    },

    updateValue(data){
      Gen.apiRest("/do/BoOurWorks", {
        data: {
          type: 'update-value',
          aow_id: this.row.aow_id,
          ...data
        }
      }).then(() => {
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })
    },

    publishOurWork(){
      this.$swal({
        title: "Are you sure to publish this?",
        icon: "question",
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        showCancelButton: true,
        confirmButtonText: 'Yes, sure',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(button => {
        if(button.isConfirmed){
          this.loadingOverlay = true
          Gen.apiRest("/do/BoOurWorks", {
            data: {
              type: 'update-value',              
              aow_id: this.row.aow_id,
              aow_is_active: 'Y'
            }
          }).then(() => {
            this.loadingOverlay = false
            this.$swal({
              icon: 'success',
              title: 'Publish successfull'
            }).then(() => this.$set(this.row, 'aow_is_active', 'Y'))
          }).catch(err => {
            this.loadingOverlay = false
            this.$swal({
              icon: 'error',
              text: err.response.data.message || 'Something went wrong!'
            })
          })
        }
      })
    }
  },
  computed: {
    globalComp(){
      return this.$parent.$parent.$parent
    },

    showAlertThumbnail(){
      return !this.row.aow_thumb_title_en || !this.row.aow_thumb_title_en ||
      !this.row.aow_project_excerpt_en || !this.row.aow_project_excerpt_id ||
      !this.row.aow_type_applications || !this.row.aow_category || !this.row.aow_thumb_img
    },
    showAlertHero(){
      return !this.row.aow_hero_img_object || !this.row.aow_title_en ||
      !this.row.aow_title_id || !this.row.aow_content_scope ||
      !this.row.aow_client_id || !this.row.aow_client_en
    },

    showAlertBlockContent(){
      if(this.sectionBlockEmpty) return true

      const firstSection = (this.row.aow_content_block||[])[0]

      return !firstSection.title_id || !firstSection.title_en ||
      !firstSection.content_en || !firstSection.content_id    
    },

    sectionBlockEmpty(){
      return !(this.row.aow_content_block||[]).length
    },

    enableButtonSubmit(){
      return !this.showAlertThumbnail && !this.showAlertHero &&
      !this.showAlertBlockContent
    }
  },

  watch: {
    'row.aow_content_block': {
      handler() {
        this.updateBlockdata(this.row)
      },
      deep: true
    },
    'row.aow_thumb_img'(v){
      this.updateValue({ aow_thumb_img: v })
    },
    'row.aow_hero_img_object'(v){
      this.updateValue({ aow_hero_img_object: v })
    },
  }
}
</script>


<style scoped>
.edit-type-application{
  text-decoration: underline;
  color: black;
  font-size: 16px;
}

.button-lang {
   position: absolute;
   top: -3.3em;
   right: 0;
}
</style>